define("discourse/plugins/discourse-kolide/discourse/controllers/preferences/kolide", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "deviceId", [_tracking.tracked], function () {
      return null;
    }))();
    #deviceId = (() => (dt7948.i(this, "deviceId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "mobileDevice", [_tracking.tracked], function () {
      return false;
    }))();
    #mobileDevice = (() => (dt7948.i(this, "mobileDevice"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "refreshing", [_tracking.tracked], function () {
      return false;
    }))();
    #refreshing = (() => (dt7948.i(this, "refreshing"), void 0))();
    get onboardingTopicLink() {
      return `/t/${this.siteSettings.kolide_onboarding_topic_id}`;
    }
    static #_7 = (() => dt7948.n(this.prototype, "onboardingTopicLink", [_object.computed]))();
    async setKolideDevice() {
      if (!this.mobileDevice && !this.deviceId) {
        this.dialog.alert({
          message: _discourseI18n.default.t("discourse_kolide.onboarding.device_empty")
        });
        return;
      }
      this.loading = true;
      const options = {
        type: "POST",
        processData: false,
        contentType: false,
        data: new FormData()
      };
      if (this.mobileDevice) {
        options.data.append("is_mobile", true);
      } else {
        options.data.append("device_id", this.deviceId);
      }
      (0, _ajax.ajax)("/kolide/devices/current", options).then(() => {
        window.location.reload();
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "setKolideDevice", [_object.action]))();
    refreshDevices() {
      this.refreshing = true;
      (0, _ajax.ajax)("/kolide/devices/refresh", {
        type: "PUT"
      }).then(devices => {
        this.model = devices;
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.refreshing = false;
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "refreshDevices", [_object.action]))();
  }
  _exports.default = _default;
});