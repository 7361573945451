define("discourse/plugins/discourse-kolide/discourse/routes/preferences-kolide", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/cookie", "discourse/routes/restricted-user"], function (_exports, _ajax, _ajaxError, _cookie, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesKolideRoute extends _restrictedUser.default {
    model() {
      return (0, _ajax.ajax)("/kolide/devices").catch(_ajaxError.popupAjaxError);
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      const deviceId = (0, _cookie.default)("kolide_device_id");
      if (deviceId) {
        if (deviceId === "mobile") {
          controller.set("mobileDevice", true);
        } else {
          controller.set("deviceId", parseInt(deviceId, 10));
        }
      }
    }
  }
  _exports.default = PreferencesKolideRoute;
});