define("discourse/plugins/discourse-kolide/discourse/initializers/extend-for-kolide", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/cookie", "discourse/lib/plugin-api", "I18n"], function (_exports, _ajax, _ajaxError, _cookie, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    const currentUser = api.getCurrentUser();
    function attachButtons(cooked) {
      const assignButtons = cooked.querySelectorAll("a.kolide-assign") || [];
      const recheckButtons = cooked.querySelectorAll("a.kolide-recheck") || [];
      assignButtons.forEach(button => {
        button.addEventListener("click", assignUser, false);
      });
      recheckButtons.forEach(button => {
        button.addEventListener("click", recheckIssue, false);
      });
    }
    function assignUser() {
      const userId = this.dataset.user;
      const deviceId = this.dataset.device;
      (0, _ajax.ajax)(`/kolide/devices/${deviceId}/assign.json`, {
        type: "PUT",
        data: {
          user_id: userId
        }
      }).then(() => {
        const dialog = api.container.lookup("service:dialog");
        dialog.alert(_I18n.default.t("discourse_kolide.device_assigned"));
      }).catch(_ajaxError.popupAjaxError);
      return false;
    }
    function recheckIssue() {
      const issueId = this.dataset.issue;
      (0, _ajax.ajax)(`/kolide/issues/${issueId}/recheck.json`, {
        type: "POST"
      }).then(() => {
        const dialog = api.container.lookup("service:dialog");
        dialog.alert(_I18n.default.t("discourse_kolide.issue_recheck_initiated"));
      }).catch(_ajaxError.popupAjaxError);
      return false;
    }
    if (currentUser) {
      api.decorateCookedElement(attachButtons, {
        onlyStream: false,
        id: "discourse-kolide-buttons"
      });
      if ((0, _cookie.default)("kolide_non_onboarded")) {
        const site = api.container.lookup("service:site");
        const siteSettings = api.container.lookup("service:site-settings");
        const onboarding_topic_id = siteSettings.kolide_onboarding_topic_id;
        if (onboarding_topic_id > 0 && !site.mobileView) {
          api.addGlobalNotice(_I18n.default.t("discourse_kolide.non_onboarded_device.notice", {
            page_link: `/u/${currentUser.username}/preferences/kolide`,
            topic_link: `/t/${onboarding_topic_id}`
          }), "non-onboarded-device", {
            dismissable: true,
            persistentDismiss: true,
            dismissDuration: moment.duration(1, "day")
          });
        }
      }
    }
  }
  var _default = _exports.default = {
    name: "extend-for-kolide",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
    }
  };
});