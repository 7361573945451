define("discourse/plugins/discourse-kolide/discourse/components/kolide-devices-dropdown", ["exports", "discourse-common/utils/decorators", "select-kit/components/dropdown-select-box"], function (_exports, _decorators, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend(dt7948.p({
    devices: [],
    classNames: ["kolide-devices-dropdown"],
    pluginApiIdentifiers: ["kolide-devices-dropdown"],
    selectKitOptions: {
      icon: null,
      showCaret: true,
      none: "discourse_kolide.onboarding.select_device",
      showFullTitle: true
    },
    content(devices) {
      return devices.map(device => {
        return {
          id: device.id,
          title: device.name,
          description: device.hardware_model,
          icon: device.is_orphan ? "question" : "user"
        };
      });
    }
  }, [["method", "content", [(0, _decorators.default)("devices")]]]));
});